import React, { useEffect, useMemo, useState } from 'react';
import { Col, Grid, Row, Table, Text, TouchField } from 'components';
import Store from 'store';
import { ColumnsType } from 'antd/es/table';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { COLOR } from 'const';
import { ValHelper } from 'helpers';
import moment from 'moment';
import { notification } from 'antd';
import { capitalize } from 'lodash';
import { useDynamicResponsiveValue } from 'quickly-react';
import { useNavFunc } from 'navigation';

export const UpsertResellerTransactions = ({ resellerId }: any) => {
  const { navigation, route } = useNavFunc();
  const rV = useDynamicResponsiveValue();
  const isMobile = rV({ xs: true, lg: false });
  const PaymentStore = Store.usePaymentStore();
  const [data, setData] = useState<any>({
    trans: [],
    hasNext: false,
    balances: null,
  });
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    PaymentStore.adminGetBalanceTransactions(resellerId).then(d => {
      console.log('d', d);
      setData(d)
    });
  }, [resellerId]);

  const onPressLoadMore = async () => {
    try {
      if (!data.trans?.length) return;
      setLoadingMore(true);
      const lastItem = data.trans[data.trans.length - 1];
      const res = await PaymentStore.adminGetBalanceTransactions(resellerId, lastItem.id);
      if (res.trans) {
        setData(s => ({
          trans: s.trans.concat(res.trans),
          hasNext: res.hasNext,
          balances: res.balances,
        }))
      }
      setLoadingMore(false);
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Order Number',
      key: 'orderNumber',
      render: (record) => {
        if (record.metadata?.type === 'refund' && record.metadata?.refundMessage) {
          const str = record.metadata?.refundMessage;
          const orderId = str.match(/Order #(\d+)/)?.[1];
          return <Text>{`#${orderId}`}</Text>;
        }
        const orderId = record?.invoiceData?.metadata?.orderId;
        if (!orderId) return null;
        return <Text>{orderId.split(',').map(i => `#${i}`).join('\n')}</Text>;
      },
    },
    {
      title: 'Product',
      key: 'productName',
      render: (record) => {
        if (record.metadata?.type) {
          if (record.metadata?.type === 'refund') {
            return record.metadata?.refundMessage || 'Refund';
          }
          if (record.metadata?.type === 'deposit') {
            const depositMessage = record.metadata?.depositMessage ? ` - ${record.metadata?.depositMessage}` : '';
            return `Deposit${depositMessage}`;
          }
          return capitalize(record.metadata?.type);
        }
        return <Text>{record?.invoiceData?.metadata?.productName}</Text>;
      },
    },
    {
      title: 'Amount Paid',
      key: 'amount',
      render: (record) => {
        return <Text color={record.amount < 0 ? "red" : undefined}>£{ValHelper.formatMoney(record.amount / 100)}</Text>
      },
    },
    {
      title: 'Date Paid',
      key: 'created',
      render: (record) => moment(record.created * 1000).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Payment method',
      key: 'paymentMethod',
      render: (record) => record?.invoiceData?.metadata?.paymentMethod || record?.metadata?.paymentMethod,
    },
    {
      title: 'Invoice/Receipt',
      key: 'actions',
      width: 60,
      render: (record) => {
        const orderId = record?.invoiceData?.metadata?.orderId;
        if (!!orderId) {
          return (
            <Row justifyContent="flex-end">
              <TouchField
                width={40} height={40} middle mr1
                onPress={() => {
                  window.open(`/api/order/gen-invoice/${orderId}`, '_blank');
                }}
              >
                <Ionicons name="open-outline" size={26} color={COLOR.FONT} />
              </TouchField>
            </Row>
          )
        }
        const pdfUrl = record?.invoiceData?.invoice_pdf;
        if (!!pdfUrl) {
          return (
            <Row justifyContent="flex-end">
              <TouchField
                width={40} height={40} middle mr1
                onPress={() => {
                  window.open(pdfUrl);
                }}
              >
                <MaterialCommunityIcons name="download" size={26} color={COLOR.FONT} />
              </TouchField>
            </Row>
          )
        }
        const receiptUrl = record?.metadata?.receiptUrl;
        if (!!receiptUrl) {
          return (
            <Row justifyContent="flex-end">
              <TouchField
                width={40} height={40} middle mr1
                onPress={() => {
                  window.open(receiptUrl);
                }}
              >
                <Ionicons name="open-outline" size={26} color={COLOR.FONT} />
              </TouchField>
            </Row>
          )
        }
        return null;
      },
    },
    {
      title: 'Stripe Link',
      key: 'stripeLink',
      width: 240,
      render: (record) => {
        const invoiceId = record?.invoice;
        const intentId = record?.metadata?.paymentIntentId;
        const stripeUrl =
          invoiceId ? `https://dashboard.stripe.com/invoices/${invoiceId}` :
            intentId ? `https://dashboard.stripe.com/payments/${intentId}` : '';
        const stripeText = invoiceId || intentId || '';
        if (!!stripeUrl) {
          return (
            <TouchField
              flexDirection={'row'}
              width={240} height={40} middle mr1
              onPress={() => {
                window.open(stripeUrl);
              }}
            >
              <Ionicons name="open-outline" size={26} color={COLOR.FONT} />
              <Col flex1 ml0>
                <Text numberOfLines={1}>{stripeText}</Text>
              </Col>
            </TouchField>
          )
        }
        return null;
      },
    },
  ];

  const balanceText = useMemo(() => {
    const _balance = (data.balances?.balance * -1) || 0;
    return ValHelper.formatBalance((_balance) / 100);
  }, [data.balances]);

  return (
    <Col flex1 m2 mv1 p2 round1 bgWhite overflow={'scroll'}>
      <Row justifyContent={'space-between'} mb2>
        <Text subtitle1>Current Balance: £{balanceText}</Text>
        <Row>
          {data.balances?.id && (
            <TouchField
              height={36}
              paddingLeft={5}
              justifyContent={'flex-start'}
              alignItems={'center'}
              flexDirection="row"
              onPress={() => {
                window.open(`https://dashboard.stripe.com/customers/${data.balances?.id}`);
              }}
            >
              <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
              <Text ml1>Stripe Customer ID: {data.balances?.id}</Text>
            </TouchField>
          )}
        </Row>
      </Row>
      {isMobile ? (
        <Col>
          {data.data.map((record, index) => {
            return (
              <Row round0 p1 m0 shadow flexWrap={'wrap'} key={'card-' + index + record.id} alignItems={'flex-start'}>
                {columns.map((column, i) => {
                  // @ts-ignore
                  const value: any = column.render(record);
                  return (
                    <Col key={'column-' + record.id + '-' + i} p0 m0 width={'calc( 50% - 10px)'}>
                      <Text fontSize={10} color={'rgba(0,0,0,0.2)'}>{column.title as string}</Text>
                      {/* //@ ts-ignore */}
                      <Text>{value || '...'}</Text>
                    </Col>
                  )
                })}
              </Row>
            );
          })}
        </Col>
      ) : (
        <Table
          data={data.trans}
          columns={columns}
          rowKey={record => `${record.id}`}
          minWidth={800}
        // loading={fetching}
        />
      )}
      {data?.hasNext && (
        <Col middle mt1>
          <TouchField
            onPress={onPressLoadMore}
            borderWidth={1}
            borderColor={COLOR.MAIN}
          >
            <Col width={200} height={40} middle>
              <Text>{loadingMore ? 'Loading more...' : 'Load more'}</Text>
            </Col>
          </TouchField>
        </Col>
      )}
      <Col height={100} />
    </Col>
  );
}
